import React from 'react';
import logo from './logo.svg';
import daisy from './assets/images/Daisy.png'
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={daisy} className="App-logo" alt="logo" />
        <p >
         Anachronic Motors - Coming soon.
        </p>
       
       
       
      </header>
    </div>
  );
}

export default App;
